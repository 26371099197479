import frutigerLightEOT from './formats/FrutigerLight.eot';
import frutigerLightWOFF from './formats/FrutigerLight.woff';
import frutigerLightTTF from './formats/FrutigerLight.ttf';
import frutigerLightSVG from './formats/FrutigerLight.svg';

import frutigerRomanEOT from './formats/FrutigerRoman.eot';
import frutigerRomanWOFF from './formats/FrutigerRoman.woff';
import frutigerRomanTTF from './formats/FrutigerRoman.ttf';
import frutigerRomanSVG from './formats/FrutigerRoman.svg';

import frutigerBoldEOT from './formats/FrutigerBold.eot';
import frutigerBoldWOFF from './formats/FrutigerBold.woff';
import frutigerBoldTTF from './formats/FrutigerBold.ttf';
import frutigerBoldSVG from './formats/FrutigerBold.svg';

const trimUrl = url => url.replace(/\.\.\//g, '');

export default {
  '@font-face': [{
    'font-family': 'Frutiger',
    'font-weight': 200,
    'font-style': 'normal',
    'fallbacks': [{src: `url(${trimUrl(frutigerLightEOT)})`}],
    'src': [
      `url(${trimUrl(frutigerLightEOT)}#iefix) format('embedded-opentype')`,
      `url(${trimUrl(frutigerLightWOFF)}) format('woff')`,
      `url(${trimUrl(frutigerLightTTF)}) format('truetype')`,
      `url(${trimUrl(frutigerLightSVG)}) format('svg')`
    ]
  }, {
    'font-family': 'Frutiger',
    'font-weight': 400,
    'font-style': 'normal',
    'fallbacks': [{src: `url(${trimUrl(frutigerRomanEOT)})`}],
    'src': [
      `url(${trimUrl(frutigerRomanEOT)}#iefix) format('embedded-opentype')`,
      `url(${trimUrl(frutigerRomanWOFF)}) format('woff')`,
      `url(${trimUrl(frutigerRomanTTF)}) format('truetype')`,
      `url(${trimUrl(frutigerRomanSVG)}) format('svg')`
    ]
  }, {
    'font-family': 'Frutiger',
    'font-weight': 700,
    'font-style': 'normal',
    'fallbacks': [{src: `url(${trimUrl(frutigerBoldEOT)})`}],
    'src': [
      `url(${trimUrl(frutigerBoldEOT)}#iefix) format('embedded-opentype')`,
      `url(${trimUrl(frutigerBoldWOFF)}) format('woff')`,
      `url(${trimUrl(frutigerBoldTTF)}) format('truetype')`,
      `url(${trimUrl(frutigerBoldSVG)}) format('svg')`
    ]
  }]
};
