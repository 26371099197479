import React from 'react';

function Construction() {
  return (
    <div className="construction">
      <h2 className="noMargin">Under Construction</h2>
      <p>This page is currently under construction. Sorry for the inconvenience.</p>
      <p>Please check back soon for more updates.</p>
    </div>
  );
}

export default Construction;
