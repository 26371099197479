import {createReducer} from 'abyss-ui/redux/utils';
import * as actions from './actions';

const initialState = {
  count: 0,
  theme: {colors: {primary: 'yellow'}}
};

const reducer = {
  [actions.INCREMENT]: (state, action) => {
    return {
      count: state.count + (action.payload || 1),
      theme: {colors: {primary: 'green'}}
    };
  },

  [actions.DECREMENT]: state => {
    return {
      count: state.count - 1,
      theme: {colors: {primary: 'red'}}
    };
  }
};

export default createReducer(initialState, reducer);
