import React from 'react';
import Helmet from 'react-helmet';
import {Switch, Route} from 'abyss-ui/router';
import Construction from 'components/Construction';
import Header from './Header';
import Home from './routes/Home';
import About from './routes/About';
import Portfolio from './routes/Porfolio';

function App() {
  const construction = false;

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Tony P Photography" />
      <Choose>
        <When condition={construction}>
          <Construction />
        </When>
        <Otherwise>
          <div className="background" />
          <div className="page">
            <Header />
            <Switch>
              <Route path="/about" component={About} />
              <Route path="/portfolio" component={Portfolio} />
              <Route path="/" exact component={Home} />
            </Switch>
          </div>
        </Otherwise>
      </Choose>
    </React.Fragment>
  );
}

export default App;
