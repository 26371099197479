import http from 'abyss-ui/redux/http';

const delayAPIPlaceholder = ms => {
  return new Promise(resolve => setTimeout(() => resolve(), ms));
};

export const fetchAlbum = async payload => {
  await delayAPIPlaceholder(1000);

  const {data} = await http({
    method: 'GET',
    url: `${__CONFIG__.SMUGMUG_API_URL}/node/rn2bNm!children`,
    query: payload
  });

  return data;
};
