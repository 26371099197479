import {createTheme} from 'abyss-ui/styles/theme';

export default createTheme({
  typography: {
    fontFamily: 'Frutiger, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  colors: {
    primary400: '#3271C3',
    primary500: '#3477DB',
    gray100: '#F2F6F9',
    gray200: '#F2F2F2',
    gray300: '#E1E4E8',
    gray500: '#DFDFDF',
    gray900: '#555555'
  }
});
