import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'abyss-ui/styles';
import {ReduxProvider} from 'abyss-ui/redux';
import {Router} from 'abyss-ui/router';
import {history} from 'state/modules';
import {configureAppStore} from 'state/store';
import {attachStylesheet} from 'styles/stylesheet';
import theme from 'styles/theme';
import App from './App';

export const store = configureAppStore();
export const styles = attachStylesheet();

function render() {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </ReduxProvider>
    </ThemeProvider>,
    document.getElementById('root')
  );
}

if (module.hot) {
  module.hot.accept('./App', () => render());
}

render();
