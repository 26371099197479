import materialEOT from './formats/MaterialIcons.eot';
import materialWOFF2 from './formats/MaterialIcons.woff2';
import materialWOFF from './formats/MaterialIcons.woff';
import materialTTF from './formats/MaterialIcons.ttf';

const trimUrl = url => url.replace(/\.\.\//g, '');

export default {
  '@font-face': [{
    'font-family': 'Material Icons',
    'font-weight': 400,
    'font-style': 'normal',
    'fallbacks': [{src: `url(${trimUrl(materialEOT)})`}],
    'src': [
      `url(${trimUrl(materialEOT)}#iefix) format('embedded-opentype')`,
      `url(${trimUrl(materialWOFF2)}) format('woff2')`,
      `url(${trimUrl(materialWOFF)}) format('woff')`,
      `url(${trimUrl(materialTTF)}) format('truetype')`
    ]
  }],
  '@global': {
    '.material-icons': {
      'font-family': 'Material Icons, sans-serif',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-size': '24px',
      'display': 'inline-block',
      'width': '1em',
      'height': '1em',
      'line-height': 1,
      'text-transform': 'none',
      'letter-spacing': 'normal',
      'word-wrap': 'normal',
      'white-space': 'nowrap',
      'direction': 'ltr',
      '-webkit-font-smoothing': 'antialiased',
      'text-rendering': 'optimizeLegibility',
      '-moz-osx-font-smoothing': 'grayscale',
      'font-feature-settings': '\'liga\''
    }
  }
};
