export default {
  '.background': {
    backgroundColor: '#ccc',
    position: 'fixed',
    height: '9000px',
    width: '9000px',
    zIndex: 1
  },
  '.page': {
    position: 'relative',
    zIndex: 2
  },
  '.headerLayout': {
    width: '100%',
    backgroundColor: '#000'
  },
  '.headerContainer': {
    width: '90%',
    margin: '0 auto',
    padding: '10px 15px',
    color: '#fff'
  },
  '.contentLayout': {
    backgroundColor: '#ccc'
  },
  '.contentContainer': {
    width: '90%',
    margin: '0 auto'
  },
  '.textCenter': {
    textAlign: 'center'
  },
  '.textRight': {
    textAlign: 'right'
  },
  '.textLeft': {
    textAlign: 'left'
  },
  '.noMargin': {
    margin: '0 !important'
  },
  '.noPadding': {
    padding: '0 !important'
  }
};
