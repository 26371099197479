import {createSaga} from 'abyss-ui/redux/utils';
import {takeEvery, call} from 'abyss-ui/redux/effects';
import * as actions from './actions';
import * as requests from './requests';

export const FETCH_ALBUM = 'sagas/smugmug/FETCH_ALBUM';

export const fetchAlbum = () => {
  return {
    type: FETCH_ALBUM,
    cache: true,
    saga: function* (action) {
      return yield call(requests.fetchAlbum, action.payload);
    }
  };
};

export default [
  takeEvery(actions.FETCH_ALBUM, createSaga(fetchAlbum))
];
