import {createStyles, baseStyles} from 'abyss-ui/styles/stylesheet';
import * as modules from './modules';

export function attachStylesheet() {
  createStyles(baseStyles(modules.theme));
  createStyles(modules.globals);
  createStyles(modules.fonts);

  if (module.hot) {
    module.hot.accept('./modules', () => {
      attachStylesheet();
    });
  }
}
