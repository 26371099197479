import React from 'react';
import {useStyles} from 'abyss-ui/hooks';
import Link from 'abyss-ui/router/Link';

const styles = {
  logoText: {
    fontSize: '25px',
    margin: 0
  },
  navigation: {
    display: 'inline-block',
    color: '#fff'
  },
  navItem: {
    display: 'inline-block',
    listItem: 'none',
    margin: '0 15px'
  },
  navLink: {
    'color': '#fff',
    'padding': '15px',
    'font-weight': '600',
    'display': 'inline-block',
    'text-decoration': 'none',
    '&:hover': {
      border: '2px solid #fff',
      borderRadius: '5px',
      padding: '13px',
      textDecoration: 'none'
    }
  }
};
function Header() {
  const classes = useStyles(styles);

  return (
    <div className="headerLayout">
      <div className="headerContainer">
        <nav className="textCenter">
          <ul className={classes['navigation']}>
            <li className={classes['navItem']}>
              <Link to="/" className={classes['navLink']}>Home</Link>
            </li>
            <li className={classes['navItem']}>
              <Link to="/about" className={classes['navLink']}>About</Link>
            </li>
            <li className={classes['navItem']}>
              <Link to="/portfolio" className={classes['navLink']}>Portfolio</Link>
            </li>
            <li className={classes['navItem']}>
              <Link to="/" className={classes['navLink']}>Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
