import {configureStore, getDefaultMiddleware} from 'abyss-ui/redux/store';
import {combineReducers, createSagaMiddleware} from 'abyss-ui/redux';
import {routerReducer, createRouterMiddleware} from 'abyss-ui/router';
import * as modules from './modules';

const createRootReducer = reducers => {
  return combineReducers({
    ...reducers,
    router: routerReducer(modules.history)
  });
};

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(modules.history);

  const store = configureStore({
    reducer: createRootReducer(modules.reducers),
    middleware: [
      ...getDefaultMiddleware(),
      sagaMiddleware,
      routerMiddleware
    ]
  });

  store.sagaTask = sagaMiddleware.run(modules.sagas);

  if (module.hot) {
    module.hot.accept('./modules', () => {
      store.replaceReducer(createRootReducer(modules.reducers));
      store.sagaTask.cancel();
      store.sagaTask = sagaMiddleware.run(modules.sagas);
    });
  }

  return store;
}
