import 'abyss-ui/form/TextInput/TextInput.css';
import 'abyss-ui/form/DateInput/DateInput.css';
import 'abyss-ui/base/Flex/Flex.css';
import app from './_app';
import flex from './_flex';
import form from './_form';
import search from './_search';
import layout from './_layout';

export default {
  '@global': {
    ...app,
    ...layout,
    ...flex,
    ...form,
    ...search
  }
};
