import {createReducer} from 'abyss-ui/redux/utils';
import * as sagas from './sagas';

const initialState = {
  data: ''
};

const reducer = {
  [`${sagas.FETCH_ALBUM}_STARTED`]: () => {
    return {
      data: ''
    };
  },

  [`${sagas.FETCH_ALBUM}_SUCCESS`]: (state, action) => {
    return {
      data: action.payload
    };
  }
};

export default createReducer(initialState, reducer);
