export default {
  '.text-medium': {
    'fontSize': '1rem'
  },
  '.text-uppercase': {
    textTransform: 'uppercase'
  },
  '.box-padding': {
    padding: '1rem 2rem'
  },
  '.box': {
    backgroundColor: '#fff'
  },
  '.relative': {
    position: 'relative'
  },
  '.hyperlink': {
    'fontWeight': 600,
    'color': '#3271c3',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '-webkit-font-smoothing': 'antialiased'
  },
  '.hyperlink.no-underline': {
    textDecoration: 'none'
  },
  '.text-center': {
    textAlign: 'center'
  }
};
