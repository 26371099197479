import React from 'react';
import Helmet from 'react-helmet';
import {useStyles} from 'abyss-ui/hooks';
import Flex from 'abyss-ui/base/Flex';
import promoShot from '../Home/promoShot.jpg';

const styles = () => ({
  promoPhoto: {
    height: '400px',
    backgroundImage: `url("${promoShot}")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center'
  },
  promoTitle: {
    margin: '0',
    textAlign: 'center',
    color: '#fff',
    fontSize: '75px'
  },
  categoriesBlock: {
    margin: '30px auto',
    width: '90%',
    backgroundColor: '#fff',
    border: '1px solid #999',
    padding: '30px'
  },
  category: {
    padding: '10px',
    boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.5)'
  }
});

function About() {
  const classes = useStyles(styles);
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Tony P Photography">
        <title>About</title>
      </Helmet>
      <div className="main">
        <Flex className={classes['promoPhoto']}>
          <Flex.Content>
            <h2 className={classes['promoTitle']}>About</h2>
          </Flex.Content>
        </Flex>
        <div className={classes['categoriesBlock']}>
          <Flex gutters>
            <Flex.Content space-100 space-large-25>
              <div className={classes['category']}>
                <h3>Nightlife</h3>
              </div>
            </Flex.Content>
            <Flex.Content space-100 space-large-25>
              <div className={classes['category']}>
                <h3>Weddings</h3>
              </div>
            </Flex.Content>
            <Flex.Content space-100 space-large-25>
              <div className={classes['category']}>
                <h3>Culinary</h3>
              </div>
            </Flex.Content>
            <Flex.Content space-100 space-large-25>
              <div className={classes['category']}>
                <h3>Misc</h3>
              </div>
            </Flex.Content>
          </Flex>
        </div>
      </div>
    </React.Fragment>
  );
}

export default About;
